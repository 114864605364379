import { CurrentUser, Site } from '@energybox/react-ui-library/dist/types';
import { View } from '@react-pdf/renderer';
import React from 'react';
import { RawInspectionReport } from '../../../reducers/inspection';
import { EdgeController } from './EdgeController';
import { EnergyPro } from './EnergyPro';
import { Hub } from './Hub';
import { Sensors } from './Sensors';
import { SiteController } from './SiteController';
import { VenstarThermostat } from './VenstarThermostat';

type Props = {
  site: Site;
  report: RawInspectionReport;
  currentUser?: CurrentUser;
};

// Create Document Component
const SiteInspectionReportPdf = ({ site, report, currentUser }: Props) => {
  const { data } = report;

  const components = [
    ...(data.edge_controllers
      ? data.edge_controllers.map((datum, index) => (
          <EdgeController key={index} data={datum} />
        ))
      : []),
    ...(data.hubs
      ? data.hubs.map((datum, index) => (
          <Hub key={index} data={datum} currentUser={currentUser} />
        ))
      : []),
    ...(data.thermostats
      ? data.thermostats.map((datum, index) => (
          <VenstarThermostat
            key={index}
            data={datum}
            currentUser={currentUser}
          />
        ))
      : []),
    ...(data.super_edge_controllers
      ? data.super_edge_controllers.map((datum, index) => (
          <EdgeController key={index} data={datum} isSuperEdge={true} />
        ))
      : []),
    ...(data.superhub_sensors
      ? data.superhub_sensors.map((datum, index) => (
          <Hub key={index} data={datum} currentUser={currentUser} isSuperHub />
        ))
      : []),
    ...(data.eb_thermostats
      ? data.eb_thermostats.map((datum, index) => (
          <VenstarThermostat
            key={index}
            data={datum}
            currentUser={currentUser}
            isEBThermostat={true}
          />
        ))
      : []),
    ...(data.site_controllers
      ? data.site_controllers.map((datum, index) => (
          <SiteController key={index} data={datum} />
        ))
      : []),
    ...(data.energy_pros
      ? data.energy_pros.map((datum, index) => (
          <EnergyPro key={index} data={datum} />
        ))
      : []),
    ...(data.energy_pro2s
      ? data.energy_pro2s.map((datum, index) => (
          <EnergyPro key={index} data={datum} isEPro2 />
        ))
      : []),

    ...(!!data.unpaired_sensors?.sensors?.length
      ? [
          <Sensors
            currentUser={currentUser}
            siteId={'' + site.id}
            data={data.unpaired_sensors.sensors}
            statisticsData={
              data.unpaired_sensors.result
                ? {
                    result: data.unpaired_sensors.result,
                    errors: data.unpaired_sensors.errors,
                    warnings: data.unpaired_sensors.warnings,
                  }
                : undefined
            }
          />,
        ]
      : []),
  ];

  console.debug(components.length);
  return (
    <>
      {components.map((content, idx) =>
        // do not break on first component
        idx === 0 ? (
          content
        ) : (
          <>
            <View style={{ flexGrow: 1 }} />
            {/* add trailing space to page break */}
            <View break={idx != 0}>{content}</View>
          </>
        )
      )}
    </>
  );
};

export default SiteInspectionReportPdf;
